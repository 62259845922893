import "core-js/modules/es.array.push.js";
import * as local from '@/libs/local';
//import HelloWorld from '@/components/HelloWorld.vue';
import { showToast } from 'vant';
import { showSuccessToast, showFailToast } from 'vant';
import Vue from 'vue';
import * as api from '@/api';
import { ref } from 'vue';
import { Tab, Tabs } from 'vant';
import { Col, Row } from 'vant';
import { Popup } from 'vant';
import { Cell, CellGroup } from 'vant';
import { Dialog } from 'vant';
import { NoticeBar } from 'vant';
import { NavBar } from 'vant';
import { Picker } from 'vant';
import { Empty } from 'vant';
import { BackTop } from 'vant';
import { Notify } from 'vant';
import { Uploader } from 'vant';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
// 引入英文语言包

import Header from '../lyout/header.vue';
import Footer from '../lyout/footer.vue';
import Ce from '../lyout/ce.vue';
export default {
  name: 'Home',
  components: {
    Header,
    Footer,
    Ce
  },
  data() {
    return {
      lang_list: {},
      select_lang: {},
      lang: {},
      cate: [],
      user: {},
      url: "",
      address: ""
    };
  },
  created: function () {
    let _this = this;
    _this.get_imdex_data();
    _this.get_url();
    this.code = this.$route.query.code ? this.$route.query.code : '0000';
  },
  methods: {
    //切换语言

    onClickLeft() {
      api.chat(0);
    },
    back() {
      window.history.back();
    },
    get_imdex_data: function () {
      api.all('/api/user/user_invite', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.lang_list = data.lang_list;
          this.select_lang = data.select_lang;
          this.lang = data.data.lang;
          this.cate = data.data.cate;
          this.user = data.data.user;
          this.address = window.location.hostname + "/#/reg?code=" + this.user.code;
        } else {
          //console.log(data);
        }
      });
    },
    goto: function (id) {
      let _this = this;
      _this.$router.push({
        name: id,
        query: {
          id: id
        }
      });
      this.$nextTick(() => {
        window.scrollTo(0, 0); // 滚动到顶部
      });
    },
    get_url: function () {
      api.all('/api/user/code_url', {}, (err, data) => {
        if (!err && data.code === 1) {
          this.url = data.data.url;
        } else {
          //console.log(data);
        }
      });
    },
    copyToClipboard11() {
      if (this.user.code == null || this.user.code == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.user.code).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    },
    copyToClipboard() {
      if (this.address == null || this.address == undefined) {
        showFailToast(this.lang.ap8);
        return;
      }
      navigator.clipboard.writeText(this.address).then(() => {
        showSuccessToast(this.lang.ap9);
      }).catch(err => {
        showFailToast(this.lang.ap10);
      });
    }
  }
};